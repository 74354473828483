import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { doRequest, parseURLParams, appShareTextString } from "./helper";
import $ from "jquery";
import "swiper/css";
import Loader from "./Loader";
import SharePopup from "./SharePopup";
import NoItem from "./NoItem";

const WallStory = () => {
  const [swiperList, setSwiperList] = useState([]);
  const [swiper2, setSwiper] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [sortBy, setSortBy] = useState("recent");
  const [showSortBy, setShowSortBy] = useState("Most Recent");
  const [isRecentActive, setIsRecentActive] = useState(true);
  const [isLikeActive, setIsLikeActive] = useState(false);
  const [isShareActive, setIsShareActive] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [sharepopup, setSharePopup] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [postId, setPostId] = useState(null);
  const [created, setCreated] = useState(null);
  const [category_hashtag, setCategory_hashtag] = useState(null);
  const [likeLoader, setLikeLoader] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [likedPost, setLikedPost] = useState([]);
  // const [postURL, setpostURL] = useState([]);
  const [PostUrl, setPostUrl] = useState([]);

  const swiper = useRef();

  var urlParams = {};

  let navigate = useNavigate();

  const loadSlides = (sort) => {
    setShowPopup(false);
    setLoader(true);
    var data = {
      action: "list-posts",

      data: sort ? sort : {},
      token: localStorage.getItem("token"),
      // token:
      //   "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
    };

    var request = doRequest("my_sewa_2022", data, true);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        if (
          data["_resultflag"] &&
          (data["_resultflag"] === 1 || data["_resultflag"] === "1")
        ) {
          // console.log('key ', data)
          if (data.lastEvaluatedKey) {
            setLastEvaluatedKey(data.lastEvaluatedKey);
          }

          if (data.items && data.items.length > 0) {
            // setLastEvaluateKey(data.lastEvaluateKey)
            setSwiperList(data.items);
          } else {
            setNoRecord(true);
          }

          swiper2.slideTo(0);
        }
        setLoader(false);
      } catch (ex) {
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    var userid = localStorage.getItem("user");
    userid = JSON.parse(userid);
    userPostLike(userid["userid"]);
    loadSlides();
    getPageUrl();
  }, []);

  const sortByFunction = (val) => {
    setSortBy(val);

    let data = $(".sortby_list").children();
    data[0].classList.remove("active");
    data[1].classList.remove("active");
    data[2].classList.remove("active");

    if (val === "recent") {
      setShowSortBy("Most Recent");
      data[0].classList.add("active");
      setIsRecentActive(true);
      setIsLikeActive(false);
      setIsShareActive(false);
    } else if (val === "like") {
      setShowSortBy("Most Liked");
      data[1].classList.add("active");
      setIsRecentActive(false);
      setIsLikeActive(true);
      setIsShareActive(false);
    } else if (val === "share") {
      setShowSortBy("Most Shared");
      setIsRecentActive(false);
      setIsLikeActive(false);
      setIsShareActive(true);
      data[2].classList.add("active");
    }

    let value = {
      sort_by: val,
    };

    loadSlides(value);
  };

  const getPageUrl = () => {
    urlParams = parseURLParams(window.location.href);

    var userid = urlParams && urlParams["userid"] ? urlParams["userid"][0] : "";
    var title = urlParams && urlParams["title"] ? urlParams["title"][0] : "";
    setUserDetails({
      userid,
      title,
    });
  };

  const navigateToHome = () => {
    navigate(`/${window.location.search}`);
  };

  const userPostLike = (userid) => {
    let data = {
      action: "user-post",
      data: {
        user_id: userid,
      },
      token: localStorage.getItem("token"),
      // token:
      //   "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
    };

    var request = doRequest("my_sewa_2022", data, true);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        if (
          data["_resultflag"] &&
          (data["_resultflag"] === 1 || data["_resultflag"] === "1")
        ) {
          let items = data.items;
          let postArr = [];
          items &&
            items.length > 0 &&
            items.map(
              (item) => item.is_liked == 1 && postArr.push(item.post_id)
            );
          // console.log(postArr);
          setLikedPost(postArr);
        }
      } catch (err) {}
    });
  };

  const saveLike = (userid, postid, isliked) => {
    let data = {
      action: "save-like",
      data: {
        user_id: userid,
        post_id: postid,
        is_liked: isliked,
      },
      token: localStorage.getItem("token"),
      // token:
      //   "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
    };

    var request = doRequest("my_sewa_2022", data, true);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        if (
          data["_resultflag"] &&
          (data["_resultflag"] === 1 || data["_resultflag"] === "1")
        ) {
        }
      } catch (err) {}
    });

    // userPostLike(userid);
  };

  const updateInfo = (likeorshare, id, createdDate) => {
    // console.log("share clicked ", likeorshare);
    // console.log("share clicked id", id);
    // console.log("share clicked data", createdDate);
    let likecount = $(`.like_count_${id}`).html() * 1;
    let sharecount = $(`.share_count_${id}`).html() * 1;
    let status = 1;
    let data = {};
    if (
      $(`#${id}`).attr("data-like") === 1 ||
      $(`#${id}`).attr("data-like") === "1"
    ) {
      status = 0;
    } else {
      status = 1;
    }

    if (likeorshare === "like") {
      data = {
        action: "update",
        data: {
          id: id,
          type: likeorshare,
          created: createdDate,
          status: status,
        },
        token: localStorage.getItem("token"),
        // token:
        //   "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
      };
    } else if (likeorshare === "share") {
      data = {
        action: "update",
        data: {
          id: id,
          type: likeorshare,
          created: createdDate,
        },
        token: localStorage.getItem("token"),
        // token:
        //   "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
      };
    }
    if (likeorshare === "like") {
      if (
        $(`#${id}`).attr("data-like") === 1 ||
        $(`#${id}`).attr("data-like") === "1"
      ) {
        $(`#${id}`).attr("data-like", 0);
        $(`#${id}`).attr("src", "/assets/images/like.svg");
        if (likecount != 0) {
          $(`.like_count_${id}`).html(likecount - 1);
        }
      } else {
        $(`#${id}`).attr("data-like", 1);
        $(`#${id}`).attr("src", "/assets/images/likefill.svg");
        $(`.like_count_${id}`).html(likecount + 1);
      }
    }
    // loadSlides(data, action);
    var request = doRequest("my_sewa_2022", data, true);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        if (
          data["_resultflag"] &&
          (data["_resultflag"] === 1 || data["_resultflag"] === "1")
        ) {
          if (likeorshare === "like") {
            $(".like_loader_" + id).hide();
          }
          if (likeorshare === "share") {
            // if (
            //   $(`#${id}`).attr("data-share") === 1 ||
            //   $(`#${id}`).attr("data-share") === "1"
            // ) {
            //   $(`#${id}`).attr("data-share", 0);
            // //   $(`#${id}`).attr("src", "/assets/images/like.svg");
            //   $(".share_count").html(sharecount - 1);
            // } else {
            //   $(`#${id}`).attr("data-share", 1);
            // //   $(`#${id}`).attr("src", "/assets/images/likefill.svg");
            //   $(".share_count").html(sharecount + 1);
            // }
            $(`.share_count_${id}`).html(sharecount + 1);
          }
        }
        setLoader(false);
      } catch (ex) {
        setLoader(false);
      }
      let url = parseURLParams(window.location.href);

      //   var userid = url && url["userid"] ? url["userid"][0] : "";
      var userid = localStorage.getItem("user");
      userid = JSON.parse(userid);
      var isLiked =
        $(`#${id}`).attr("data-like") === 1 ||
        $(`#${id}`).attr("data-like") === "1"
          ? 1
          : 0;
      saveLike(userid["userid"], id, isLiked);
      //   userPostLike(userid);
      setLikeLoader(false);
    });
  };

  const closeSharePopup = () => {
    setSharePopup(false);
  };

  const handleShare = async (item) => {
    var org_url = "test";

    if (false && window.location.href.indexOf("appversionios") != -1) {
      let category_hashtag =
        (item.category_hashtag && item.category_hashtag != "null"
          ? item.category_hashtag
          : "") + " ";
      let quote =
        "I have offered a special Gift of Seva for PM Modi on his birthday! You too can gift him a Seva on his special day via the NaMo App! " +
        category_hashtag +
        " #HappyBdayModiJi https://nm-4.com/seva";

      let data = {
        title: "HappyBdayModiJi",
        text: quote,
        // url: "https://nm-4.com/seva",
      };

      setPostId(item.id);
      setCreated(item.created);
      setPostUrl(item.post_url);
      setCategory_hashtag(item.category_hashtag);

      if (navigator && navigator.canShare && navigator.canShare(data)) {
        // document.getElementById("preloader_mantra").style.display = "block";

        // $(".preloader_bg").hide();
        const mediafile = item.post_url;
        const fileSpliteString = mediafile.split(".");
        let extension = fileSpliteString[fileSpliteString.length - 1];
        const filename = `${Math.random()}_img.${extension}`;

        const response = await fetch(mediafile);
        const blob = await response.blob();
        const file = new File([blob], filename, {
          type: blob.type,
        });
        data["files"] = [file];
        console.log(data);
        navigator
          .share(data)
          .then(() => {
            console.log("Successfully native shared data");
          })
          .catch((error) => {
            console.log("Error native sharing", error);
            // if (ref.state.sharePopup) {
            //   ref.showSharePopup();
            // }
          });
        // document.getElementById("preloader_mantra").style.display = "none";
      } else {
        setSharePopup(true);
      }
    } else {
      // let text =
      //   (item.category_hashtag && item.category_hashtag != "null"
      //     ? item.category_hashtag
      //     : "") + "#HappyBdayModiJi";

      let category_hashtag =
        (item.category_hashtag && item.category_hashtag != "null"
          ? item.category_hashtag
          : "") + " ";

      let quote =
        "I have offered a special Gift of Seva for PM Modi on his birthday! You too can gift him a Seva on his special day via the NaMo App! " +
        category_hashtag +
        " #HappyBdayModiJi https://nm-4.com/seva";
      try {
        //indexOf appversion
        if (window.location.href.indexOf("appversionios") != -1) {
          var sharelink = `appshare://?text= ${encodeURIComponent(
            appShareTextString(quote)
          )}&imageurl=${encodeURIComponent(item.post_url)}`;
          // alert(sharelink);
          // console.log(sharelink, "sharelink");

          // console.log(this.props.sharedata.shareCount, 'shareCount');
          // this.getDetails()
          updateInfo("share", item.id, item.created);
          window.location.href = sharelink;
        } else {
          var sharelink = `appshare://text= ${appShareTextString(
            quote
          )}&imageurl=${item.post_url}`;
          // alert(sharelink);
          console.log(sharelink, "sharelink");

          // console.log(this.props.sharedata.shareCount, 'shareCount');
          // this.getDetails()
          updateInfo("share", item.id, item.created);
          window.location.href = sharelink;
        }
      } catch (e) {
        // this.handleNativeShare();
      }
    }
  };

  const loadMore = () => {
    var data = {
      action: "list-posts",

      data: {},
      lastEvaluatedKey: lastEvaluatedKey,
      token: localStorage.getItem("token"),
    };

    var request = doRequest("my_sewa_2022", data, true);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        if (
          data["_resultflag"] &&
          (data["_resultflag"] === 1 || data["_resultflag"] === "1")
        ) {
          // console.log('key ', data.lastEvaluatedKey)
          if (lastEvaluatedKey) {
            setLastEvaluatedKey(data.lastEvaluatedKey);
          } else {
            setLastEvaluatedKey(null);
          }

          if (data.items && data.items.length > 0) {
            let items = [];
            items = data.items;
            // setLastEvaluateKey(data.lastEvaluateKey)
            setSwiperList([...swiperList, ...items]);
          } else {
            setNoRecord(true);
          }
        }
        setLoader(false);
      } catch (ex) {
        setLoader(false);
      }
    });
  };

  // console.log("last key ", lastEvaluatedKey);
  return (
    <>
      <div className="wallstory_section">
        <ul className="wallstory_back">
          <li>
            <img
              src="/assets/images/chevron.svg"
              alt=""
              onClick={navigateToHome}
            />
          </li>
          <li>
            <h5>{urlParams && urlParams["title"] && urlParams["title"][0]}</h5>
          </li>
        </ul>
        {noRecord && <NoItem />}
        <Swiper
          spaceBetween={30}
          ref={swiper}
          direction={"vertical"}
          lazy={{ loadPrevNext: true }}
          scrollbar={{ draggable: true }}
          slidesPerView={1}
          className="swipper_slide"
          onSlideChange={(swiper) => {
            if (swiper.isEnd && lastEvaluatedKey) {
              loadMore();
            }
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          loop={false}
        >
          {swiperList &&
            swiperList.length > 0 &&
            swiperList.map((item, index) => {
              // let url = item.post_urls;
              let newUrl = [];
              // if (url !== "null") {
              //   newUrl = url.split(",");
              // }

              return (
                <SwiperSlide key={`Slide-${index}`}>
                  {/* <img
                      src="/assets/images/img-2.jpg"
                      className="img_wall"
                      alt=""
                    /> */}
                  {/* pehle ka code ye h
                 <img src={item.post_url} className="img_wall" alt="" />  */}

                  {/* maya code niche h  by vashisht */}

                  {newUrl && newUrl.length > 0 ? (
                    <div className="multipleimage_div">
                      <ul className="multiple_images">
                        {" "}
                        {newUrl.map((item1, index) => {
                          return (
                            <li>
                              <img src={item1} className="" alt="" />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <img
                      src={item.post_url
                        .split("https://campaign-images.narendramodi.in/")
                        .join("https://nm-seva-photos.b-cdn.net/")}
                      className="img_wall"
                      alt=""
                    />
                  )}
                  {/* <img
                    onClick={navigateToHome}
                    src="/assets/images/camera3.svg"
                    className="camera_pos"
                    alt=""
                  /> */}
                  {/* <img
                    src="/assets/images/twochev.svg"
                    className="chevron_pos"
                    alt=""
                  /> */}

                  <div className="user_list">
                    <ul>
                      <li className="loader_list">
                        <a
                          href="#"
                          onClick={() => {
                            setLikeLoader(true);
                            updateInfo("like", item.id, item.created);

                            $(".like_loader_" + item.id).show();
                          }}
                        >
                          {likedPost &&
                          // likedPost.length > 0 &&
                          likedPost.indexOf(item.id) < 0 ? (
                            <img
                              id={item.id}
                              data-like={0}
                              src="/assets/images/like.svg"
                              alt=""
                            />
                          ) : item.like_count != 0 ? (
                            <img
                              id={item.id}
                              data-like={1}
                              src="/assets/images/likefill.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              id={item.id}
                              data-like={0}
                              src="/assets/images/like.svg"
                              alt=""
                            />
                          )}

                          <span className={`like_count like_count_${item.id}`}>
                            {item.like_count}
                          </span>
                        </a>
                        {/* {likeLoader && ( */}
                        <div
                          className={"like_loader like_loader_" + item.id}
                          // src="/assets/images/loader.png"
                          // alt=""
                        ></div>
                        {/* )} */}
                      </li>

                      <li
                        onClick={() => {
                          // setPostId(item.id);
                          // setCreated(item.created);
                          // setSharePopup(true);

                          handleShare(item);
                        }}
                      >
                        <a href="#">
                          <img
                            id={item.id}
                            data-like={0}
                            src="/assets/images/share.svg"
                            alt=""
                          />
                          <span
                            className={`share_count share_count_${item.id}`}
                          >
                            {item.share_count}
                          </span>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <img
                            onClick={() => setShowPopup(true)}
                            src="/assets/images/sort.svg"
                            alt=""
                          />
                          <span>{showSortBy}</span>
                        </a>
                      </li>

                      {/* <li>
                        <a href="#">
                          <img
                            src={
                              item.avatar
                                ? item.avatar
                                : "/assets/images/userdefault.png"
                            }
                            className="user_img"
                            alt=""
                          />
                          <span>{item.user_name}</span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>

      {showPopup && (
        <div className="sortby_bg">
          <div
            className="sortbg_tansparent"
            onClick={() => setShowPopup(false)}
          ></div>
          <div className="sortby_pos">
            <h4>Sort by</h4>
            <ul className="sortby_list">
              <li
                className={isRecentActive && `active`}
                onClick={() => sortByFunction("recent")}
              >
                <a href="#">
                  Most Recent
                  {isRecentActive && (
                    <img src="/assets/images/check.svg" alt="" />
                  )}
                </a>
              </li>
              <li
                className={isLikeActive && `active`}
                onClick={() => sortByFunction("like")}
              >
                <a href="#">
                  Most Liked
                  {isLikeActive && (
                    <img src="/assets/images/check.svg" alt="" />
                  )}
                </a>
              </li>

              <li
                className={isShareActive && `active`}
                onClick={() => sortByFunction("share")}
              >
                <a href="#">
                  Most Shared
                  {isShareActive && (
                    <img src="/assets/images/check.svg" alt="" />
                  )}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {loader && <Loader />}
      {sharepopup && (
        <SharePopup
          close={closeSharePopup}
          updateInfo={updateInfo}
          id={postId}
          PostUrl={PostUrl}
          created={created}
          category_hashtag={category_hashtag}
        />
      )}
    </>
  );
};

export default WallStory;
