export const twitterShareLink = "https://twitter.com/intent/tweet?text=";
export const facebookShareLink =
  "https://www.facebook.com/dialog/share?app_id=445815495479697";
export const whatsappShareLink = "https://api.whatsapp.com/send?text=";

export const googleShareLink = "https://plus.google.com/share?url=";

export const shareableUrl = "https://stage.narendramodi.in/kamalpushp";

export const detailsPageBaseUrl = "https://www.narendramodi.in/kamalpushp/";
// export const detailsPageBaseUrl = "https://live.narendramodi.in/kamalpushp/";
// https://www.narendramodi.in/kamalpushp/1632471512399

export const imgUrl = `https://cheer4india.narendramodi.in`;

export const apiUrl = "https://campaign-api.narendramodi.in/";
export const postApiName = "kamal-pushpa";

export const _token =
  "U2FsdGVkX1+6xZSquhp+9FRCTowbbo9Qld+FrsBig+6Q3J87AoNH9lY7tx6vzaMWmcUkS+LG2wAbOFJQ9Qy34WrqUnWUy+dVTKQydY2fYVzCBkhjZ/x3tLSMBQxJqJGxzCPh9AP0n2pN9CJ5KZuKKF1XCRIi3C1Q79sXQItY/qcCs7G2oBPaBqhrbP0WrmnDVmbGT6bWCYvoeLFk50boHZdOx9yL80+JbSHuEaxh6qEmkTKEr2rIYsusZcCZEUA7yHNX7QuUY3Vd0F3g6162CHxVHPtBux0kwQqY0/7+2UeXtgwkinwXp3AKM3KYBHT9V+FR1iD2cjlYQK9HebBl3YDgEV3QSDLKWXyOmeFPAbE=";

//   "I shared a ‘Seva aur Samarpan’ story of a BJP worker who worked selflessly for the nation. You too can on NaMo App’s Kamal Pushp module.#SevaSamarpan";
export const shareDefaultText =
  "I shared an inspirational story of a BJP worker who worked selflessly for the nation. You too can on NaMo App’s Kamal Pushp module.";
export const shareDefaultText2 =
  "I read an inspirational story of a BJP worker who worked selflessly for the nation. You too can read it on NaMo App’s Kamal Pushp module.";

export const imgBaseUrl =
  "https://campaign-images.narendramodi.in/vivekanand2021/";

export const storyShareUrl = (defaultLang = "") => {
  let lang = defaultLang ? defaultLang + "/" : "";
  if (
    window.location.href.indexOf("lang=hi") !== -1 ||
    (localStorage.getItem("org_url") &&
      localStorage.getItem("org_url").indexOf("lang=hi") !== -1)
  ) {
    lang = "hi/";
  }
  return `https://www.narendramodi.in/${lang}kamalpushp/story/`;
};
