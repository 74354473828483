import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PhotoWall from "./PhotoWall";
import ThankYou from "./ThankYou";
import Webcam from "react-webcam";
import { dataURLToBlob, doRequest, parseURLParams } from "./helper";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import Loader from "./Loader";
import $ from "jquery";
import PopUp from "./PopUp";
import CameraComp from "./CameraComp";
var EXIF = require("exif-js/exif.js");
const isS3Upload = true;

const Home = () => {
  const bucketname = "js-sdk-bucket";
  AWS.config.region = "ap-south-1"; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-south-1:84027705-475d-4ca1-bac8-b2eb7407ae9f",
  });
  const bucket = new AWS.S3({
    params: {
      Bucket: bucketname,
    },
  });

  const [file, setFile] = useState("");
  const [MultipleFiles, setMultipleFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [filename, setFilename] = useState("");
  const [photoSelected, setPhotoSelected] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [camera, setCamera] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [loaderTiming, setLoaderTiming] = useState(1);
  const [facingModeUser, setFacingModeUser] = useState(true);
  const [photoArr, setPhotoArr] = useState([]);
  const [popup, setPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [cameraMode, setCameraMode] = useState(true);

  let navigate = useNavigate();

  const progressLoader = (startval, endval) => {
    var width = startval;
    var identity = setInterval(scene, 10);
    function scene() {
      if (width >= endval) {
        clearInterval(identity);
      } else {
        width++;
        // setLoaderTiming(width);
      }
    }
  };

  const showPopup = (msg) => {
    setPopup(true);
    setPopupMsg(msg);
  };

  const closePopup = () => {
    setPopup(false);
  };

  const uploadImage = (e) => {
    try {
      let obj = {};
      window.gtag("event", "upload picture event", obj);
    } catch (error) {}
    // return false;
    // setMultipleFiles
    let files = e.target.files;
    if (files.length > 9) {
      showPopup("You can upload up to 9 images at once");

      return false;
    }

    // console.log("files ", files);

    var sessionid = Date.now();
    let ary = [];
    let photosArray = [];

    console.log(e.target.files);
    // return false;
    if (e?.target?.files) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        let extension = element.type.split("/");
        extension = extension[extension.length - 1];
        console.log(extension);

        if (
          // element.type != "image/jpeg" &&
          // element.type != "image/png" &&
          // element.type != "image/jpg"
          element.type.indexOf("image/") < 0
        ) {
          showPopup("You can only upload images.");

          return false;
        }
        let random = Math.floor(100000 + Math.random() * 900000);

        ary.push({
          path: `my_sewa/${sessionid}_${random}_${index}.${extension}`,
          file: element,
        });
        photosArray.push(element);
      }
      // return false;
      // console.log(ary, "ary");
      setMultipleFiles(ary);
      setPhotoArr(photosArray);
      let profile_image = `my_sewa/${sessionid}.jpg`;
      setProfileImage(profile_image);
      const [file] = e.target.files;
      setFile(URL.createObjectURL(file));
      setPhotoSelected(true);
      setSelectedFile(e.target);
    } else {
      console.log("e is ", e.value);
    }
  };

  const falsePhotoSelected = () => {
    setPhotoSelected(false);
    setFacingModeUser(true)
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
  };

  const webcamRef = React.useRef(null);

  const getImageData = (img, imgSrc) => {
    var sessionid = Date.now();
    let image = dataURLToBlob(img);
    let file = new File([image], `${sessionid}.jpg`);

    // console.log("file ", file);

    let profile_image = `my_sewa/${sessionid}.jpg`;
    setProfileImage(profile_image);
    let files = [file];

    console.log(files);
    let ary = [];
    let photosArray = [];
    if (files) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        let random = Math.floor(100000 + Math.random() * 900000);

        ary.push({
          path: `my_sewa/${sessionid}_${random}_${index}.jpg`,
          file: element,
        });
        photosArray.push(element);
      }
      // console.log(ary, "ary");
      setMultipleFiles(ary);
      setPhotoArr(photosArray);
    }
    setSelectedFile([file]);

    // setFile(imgSrc);
    setCamera(false);
    setPhotoSelected(true);
  };

  const capture = React.useCallback(() => {
    try {
      let obj = {};
      window.gtag("event", "take picture event", obj);
    } catch (error) {}
    console.log("clicked");
    var sessionid = Date.now();
    const imageSrc = webcamRef.current.getScreenshot();
    // console.log("imageSrc ", imageSrc);
    var resizedImage = dataURLToBlob(imageSrc);
    let file = new File([resizedImage], `${sessionid}.jpg`);

    // console.log("file ", file);

    let profile_image = `my_sewa/${sessionid}.jpg`;
    setProfileImage(profile_image);
    let files = [file];

    console.log(files);
    let ary = [];
    let photosArray = [];
    if (files) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        let random = Math.floor(100000 + Math.random() * 900000);

        ary.push({
          path: `my_sewa/${sessionid}_${random}_${index}.jpg`,
          file: element,
        });
        photosArray.push(element);
      }
      // console.log(ary, "ary");
      setMultipleFiles(ary);
      setPhotoArr(photosArray);
    }
    setSelectedFile([file]);

    setFile(imageSrc);
    setCamera(false);
    setPhotoSelected(true);
  }, [webcamRef]);

  ///uploading file direct to aws s3
  const uploadToS3 = async (file, fileKey, callback, index, path) => {
    var sessionid = Date.now();

    progressLoader(20, 50);
    // var bucketUrl = "https://s3.ap-south-1.amazonaws.com/" + bucketname + "/";
    var originalKey = fileKey ? fileKey : `my_sewa/${sessionid}.jpg`;
    var params = {
      Key: originalKey,
      ContentType: file && file.type ? file.type : "image/jpeg",
      // ContentEncoding: 'base64',
      // ContentDisposition: 'inline',
      // ContentType: renditiondata.mimetype,
      Body: file,
      ACL: "public-read",
    };
    // if (!callback) {
    //   callback = (err, data) => {
    //     console.log("uploaded data......", data);
    //   };
    // }
    // let ret = null;

    // await new Promise(async (resolve, reject) => {
    if (!callback) {
      callback = (err, data) => {
        // console.log(index);
        handleSave(
          path,
          MultipleFiles.length - 1 != index ? false : true,
          index
        );
        // console.log("uploaded data......", data);
      };
    }
    await bucket.putObject(params, callback);
    // resolve(1);
    // ret = true;
    // });

    // return ret;

    // alert('file uploaded on S3.')
  };

  const RilApiForLeaderboard = (post_id, cat) => {
    let token = localStorage.getItem("riltoken");

    let json = {
      "#LiFEProPlanet": { cat_code: "LFE", cat_name: "LIFE_PRO_PLANET_PEOPLE" },
      "#VocalForLocal": { cat_code: "VFL", cat_name: "VOCAL_FOR_LOCAL" },
      "#DigitalIndia": { cat_code: "LDI", cat_name: "LEADING_DIGITAL_INDIA" },
      "#TBFreeIndia": { cat_code: "TFI", cat_name: "TUBERCULOSIS_FREE_INDIA" },
      "#AatmanirbharBharat": {
        cat_code: "ANB",
        cat_name: "AATMANIRBHAR_BHARAT",
      },
      "#CatchTheRain": { cat_code: "CTR", cat_name: "CATCH_THE_RAIN" },
      "#EkBharatShreshthBharat": {
        cat_code: "BSB",
        cat_name: "EK_BHARAT_SHRESHTH_BHARAT",
      },
      "#DonateBlood": { cat_code: "DTB", cat_name: "DONATE_BLOOD" },
      "#SwachhBharat": { cat_code: "SCB", cat_name: "SWACHH_BHARAT" },
    };

    let category = cat ? json[cat].cat_code : "";

    let jsonparams = JSON.stringify({
      videoId: post_id,
      category: category,
    });
    alert(jsonparams);

    var settings = {
      url: "https://sevauatapi.narendramodi.in/nmapi/api/points/image/update",
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: jsonparams,
    };

    $.ajax(settings).done(function (response) {
      alert(JSON.stringify(response));
      if (response && response.customCode) {
        alert(response.message);
      }
    });
  };

  const save = async (photos) => {
    // console.log("save photos ", photos);

    setLoader(true);
    progressLoader(1, 20);

    let file = "";
    if (selectedFile.files) {
      file = selectedFile.files[0];
    } else {
      file = selectedFile[0];
    }

    let files = MultipleFiles;

    for (let index = 0; index < files.length; index++) {
      const element = files[index];

      await uploadToS3(element.file, element.path, "", index, element.path);

      // handleSave(element.path, files.length - 1 != index ? false : true);
      // console.log(data);
      // if (data == true) {
      //   count++;
      // }
    }

    // handleSave();

    // uploadToS3(file, profileImage, handleSave, "profile_image");
  };

  const handleSave = (profileImage, last, index) => {
    let alias_url = "https://campaign-images.narendramodi.in/";
    let fileFullurl = alias_url + profileImage;
    let avatar = "";
    let user_id = "";
    let user_name = "";

    var urlParams = parseURLParams(window.location.href);

    try {
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      user_id = user.userid;
      user_name = user.user_name_display;
      avatar = user.user_avatar;
    } catch (error) {}

    let files = MultipleFiles;

    let pathary = [];

    for (let index = 0; index < files.length; index++) {
      const element = files[index];

      pathary.push("https://campaign-images.narendramodi.in/" + element.path);
    }

    // let allFilesPath = pathary.join(",");

    let cat =
      urlParams && urlParams["category_hashtag"]
        ? urlParams["category_hashtag"][0]
        : "";
    cat = cat ? cat.split("?")[0] : ""; // this for  remove ?userid

    let obj = {
      avatar,
      user_id,
      post_url: fileFullurl,
      user_name,
      platform: "App",
      state: urlParams && urlParams["state"] ? urlParams["state"][0] : "",
      constituency:
        urlParams && urlParams["constituency"]
          ? urlParams["constituency"][0]
          : "",
      category_hashtag: cat,
      post_urls: "",
    };

    var data = {
      action: "save",
      token: localStorage.getItem("token"),
      // token:
      // "U2FsdGVkX1+P+bOPZMD4oKMAeOiIPYomTTPHn9wVqM5+LLJmNBMoyBVD+Dx8SekHXn5Q3KOFP6KaWwxoPWtKj3ruEyvIYbg7H68A2EHmK0UgLcZeyh6pGGaL3x74lmEg05Qd8WwhNZe+fYYE8dKSRd58BPx/eAO+Gao0tQ3qxFT+9uUNEq0bcsYY6Gteg+S/9O7aCM7gciolzxbWGlbmrpV3hzFtgzDGXMl1YAIxhSzcaDL49BfsorgSOn6jOWPEnmuATLGK+GvWkkmY/Vj2U9hKNR3GRjwo8a8TuLYSVAhc0ZdMWM7Uo+mUu6GtgEzlEl2pFYr/DzP7dlSSD0bNzM+cm85n9RP925mfAtLV9GObFJjl09/U63ti0ZBwv41xgKrQk6IUsye1i2ydexIqAPyt1ktMUh/NiiKqEsCdfCJSSaAhhyV/27rrGvcOHy2u8tG1o48zFNMMxN1iPacxNx/C7otvDhUUYh4/Knf8CR5yQjEuE0GNYgIt0kx1wPwmvbz7c/oKK9MnjNPTTsSMN2qWIZq7BfQb8mHgmm0y6w24s54SwB+6OeLPi29NqjOzeZi23XAh6dYTGxj9GBDZT3bCR4LHM8Kt1sd0SksOa0u+CCoV18QJxg6LS5MSXor56JRP5Bj8y0tpUn85jIusSxgDCnaCalJQDXWr049wx0KQUbBV4zHvP+JjNc1ZsRi6DEZzjRdOIHBR4gXK171xCcXCWxBjtECwb/Z7TWA4LNZcZO6LdhoUIWTB5olUJjP1k3JdGyOiaHLhW0Z+dyrn95+7BJXa/zWh65GrF9MgfuaKJ0pdVPzpfYiQG1lpXl2M51Oupz8fmv8sDP5w1LDdx4VFMRIXOjiHmG62yVKkhY3uzd5KyQKOe/NpwtgWaT9OItvtqktbym88+T7HRnnenTNeeOclU0M0IeguakyaA4SPTqtrTUnEYoToO70ege//GRdB/2N9DLhkRufKnug68l2t8Vj1qBD2AxnjirkD3oY5EOnT/fdJmF6iERxN+DbQ2AxjUK6Dyl9uSyiuv4z8WS+7HHq9UYF3O2z1lH0yF/cuU3ODRWKNlYywmfepFsMRm78dMGvZ+7YsnpKJe7IxmTamzoVTgLT9ohdgl5FaT7FudWE+4h8Dlrxz/25aeFwlT5Zttkx1IdOe7C5VZJVFIDrGC2lKWg3eJW987gT8l/Y279KaW6etS+wv1/C7xFNOO07fnux6y01+kQh6puyIg3hhKK/03FOVdIXC7mBtrIrQ/AGiTSnFOltXpzJMyFzDlv1z36rpcF3VrGRDXO8o7h3DwWBTwE8ghv8sYZdrjy38GTaHNQp96dAD6N6xsMqK5oxpklz8xNA2RtHAN1wNzdkFfFrG0tXGYZRdE/riEo+/TsewRvfW1mX0nQTBAOKoughfrVyrZej+XQ1suVDnxxkKmgxbiPvF/X2O8RkooprDl9v7KXXfO8uEAcWAQne2w2LwcOYXKzFpu5UAYHn8o6VoBVl31fWVRTcHaICHncqZNaW35AmePGrcmZaKST1KpEFsI1dDDBcvrmxUWJymO5kEWnkVOfR3sB/TQOLQeQwqjsjAlzHnq7e6Es4Va0e2rcH77sjSgGmLcEipSMXnvqrietucGvCzhvf0qFKlNwOE3Hnn9fWwEv5Zr7+hjlNwxNb2SHmcle+nxol89Kgt81sUajUcHl+noIJSuB5c7iN2ZI7lAUI20UqBx28L8h66BeVn59b0C7RQ3VKSNGsZpRLovsuhJuim/SlFeH9opCpnOgjJxg9PYv1gYst7Zoi905BgcfzabWsLQ3D+DWygyzBdk5luceGv6hi4Xsla7tAxK++/hASvQld/Npc3l+5t",
    };
    data["data"] = obj;

    // setLoader(true);
    if (index == 0) {
      progressLoader(51, 100);
    }
    // alert(cat);

    var request = doRequest("my_sewa_2022", data, true);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        // alert(JSON.stringify(data));
        if (data["_resultflag"] && data["_resultflag"] === 1) {
          try {
            let obj = {};
            window.gtag("event", "save picture event", obj);
          } catch (error) {}
          if (localStorage.getItem("riltoken")) {
            // alert(cat);
            // RilApiForLeaderboard(data.id, cat);
          }
          if (last) {
            setTimeout(() => {
              if (photoSelected) {
                setPhotoSelected(false);
              }
              setCompleted(true);

              if (camera) {
                setCamera(false);
              }
              setLoader(false);
            }, 1000);
          }
        } else {
          setLoader(false);
        }
      } catch (ex) {
        alert(ex.message);
        setLoader(false);
      }
    });
  };

  const getPageUrl = () => {
    var urlParams = parseURLParams(window.location.href);

    var userid = urlParams && urlParams["userid"] ? urlParams["userid"][0] : "";
    var title = urlParams && urlParams["title"] ? urlParams["title"][0] : "";
    setUserDetails({
      userid,
      title,
    });
  };

  useEffect(() => {
    getPageUrl();
  }, []);

  const closeThankYou = () => {
    setCompleted(false);
  };

  const pushUrlToWallStory = () => {
    navigate(`/wallstory${window.location.search}`);
  };

  const updateSelectedPhotos = (index) => {
    let arr = [...photoArr];
    arr.splice(index, 1);
    setPhotoArr(arr);
    var sessionid = Date.now();
    let ary = [];

    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];

        ary.push({ path: `my_sewa/${sessionid}_${index}.jpg`, file: element });
      }
      // console.log(ary, "ary");
      setMultipleFiles(ary);
    }
  };

  // console.log("photoselectedd ", photoSelected);s

  const grantCameraPermission = async () => {
    if (camera) {
      let stream = null;
      const constraints = {
        video: true,
      };
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        /* use the stream */
        console.log("stream ", stream.active);
      } catch (err) {
        /* handle the error */
        // console.log("err ", err);
        // showPopup('"You need to grant permission to camera');
        // setCamera(false);
        navigator.permissions
          .query({ name: "camera" })
          .then((permissionObj) => {
            console.log("permission", permissionObj.state);

            if (permissionObj.state == "denied") {
              showPopup("You need to grant camera permissions to proceed");
              setCamera(false);
              // permissionObj.state == "prompt";
            }
          });
      }
    }
  };

  const successCallback = () => {
    return;
  };

  const errorCallback = () => {
    navigator.permissions.query({ name: "camera" }).then((permissionObj) => {
      console.log("permission", permissionObj.state);
      if (permissionObj.state == "denied") {
        // askCameraPermission();
        permissionObj.state = "prompts";
      }
    });
  };

  const askCameraPermission = () => {
    const constraints = {
      video: true,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(successCallback, errorCallback);
  };

  useEffect(() => {
    if (camera) {
      grantCameraPermission();
    }
  }, [camera, navigator.permissions]);

  const closeCamera = () => {
    setCamera(false);
  };

  const cameraModeFun = (mode) => {
    setCameraMode(mode);
  };

  return (
    <>
      {/* <p>{window.location.search}</p> */}
      {!photoSelected && !camera && !completed && (
        <div className="native_bg">
          <div className="native_bg_pos">
            {/* <h4>Native Gallery</h4> */}
            <ul className="file_list">
              <li>
                <div className="upload_div">
                  <input
                    id="uploadingImage"
                    type="file"
                    multiple
                    // accept="image/jpg,image/png,image/jpeg"
                    accept="image/*"
                    onChange={(e) => uploadImage(e)}
                  />
                  <h6>
                    <img src="/assets/images/upload.png" alt="" /> Upload Image
                  </h6>
                </div>
              </li>
              <li>
                <h6 onClick={() => setCamera(true)}>
                  <img src="/assets/images/camera.png" alt="" />
                  Take Photo
                </h6>
              </li>
            </ul>
          </div>
          {popup && <PopUp message={popupMsg} close={closePopup} />}
        </div>
      )}

      {loader && (
        <div className="loder_bg">
          <div className="loader_pos">
            <div className="loader_div">
              {/* <span className="loader">{loaderTiming}%</span> */}
              <span className="loader"></span>
            </div>
            <h6>Uploading your entry</h6>
            <h6>please wait...</h6>
          </div>
        </div>
      )}

      {photoSelected && (
        <PhotoWall
          cameraMode={cameraMode}
          image={file}
          falsePhotoSelected={falsePhotoSelected}
          save={save}
          allPhotosSelected={photoArr}
          updateSelectedPhotos={updateSelectedPhotos}
        />
      )}

      {completed && (
        <ThankYou close={closeThankYou} pushUrl={pushUrlToWallStory} />
      )}

      {camera && (
        <Webcam
          className="webcamera_pos"
          audio={false}
          // height={720}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          // width={1280}
          videoConstraints={{
            ...videoConstraints,
            ...{ facingMode: facingModeUser ? "environment" : "user" },
          }}
        >
          {({ getScreenshot }) => (
            <>
              <div className="cap_btn_flex">
                
                <button
                  className="cap_btn"
                  onClick={() => setFacingModeUser(!facingModeUser)}
                >
                  {facingModeUser ? "Open Front Camera " : "Open Rear Camera"}
                </button>
                <button className="cap_btn" onClick={() => capture()}>
                  Capture photo
                </button>
                <button
                  className="cap_btn"
                  onClick={() => {
                    setCamera(false);
                    // setOpenCamera(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </Webcam>
        // <CameraComp
        //   camera={camera}

        //   cameraModeFun={cameraModeFun}
        //   closeCamera={closeCamera}
        //   getImageData={getImageData}
        //   showPopup={showPopup}
        // />
      )}
    </>
  );
};

export default Home;
