import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { doRequest, parseURLParams } from "../helper";
import Home from "../Home";
import PhotoWall from "../PhotoWall";
import ThankYou from "../ThankYou";
import Welcome from "../Welcome";
import WallStory from "../WallStory";
import $ from "jquery";

const Routers = () => {
  const getJwtToken = async (user_id) => {
    var form = new FormData();
    form.append("addressid", user_id);
    form.append("action", "sevajwttoken");

    var settings = {
      url: "https://stage.narendramodi.in/mlapiv1",
      method: "POST",
      timeout: 0,
      processData: false,
      mimeType: "multipart/form-data",
      contentType: false,
      data: form,
    };

    $.ajax(settings).done(function (response) {
      let res = response && JSON.parse(response);
      if (res && res._resultflag == 1) {
        let token = res.sevajwttoken;
        if (token) {
          localStorage.setItem("riltoken", token);
        }
      }
    });
  };
  const getUserInfo = async (async) => {
    var urlParams = parseURLParams(window.location.href);
    let user_id = "";

    try {
      let href = window.location.href;
      href = href.split("?userid=")[1];
      user_id = href.split("&")[0];
    } catch (error) {}

    // if (urlParams && urlParams["userid"]) {
    var userid =
      urlParams && urlParams["userid"]
        ? urlParams["userid"][0]
        : user_id
        ? user_id
        : "";
    // alert(userid)

    // getJwtToken(user_id);
    //var userid = 'f1eed964148ece438a057f72a019d1df1397421c4fbcb1196e849e97f93260bf';

    var data = {
      action: userid ? "login-user" : "unlogin-user",
    };
    if (userid) {
      data["data"] = {
        userid: userid,
      };
    }
    var request = doRequest("my_sewa_2022", data, async);
    request.addEventListener("load", function () {
      try {
        var data = JSON.parse(request.responseText);
        if (data["_resultflag"] && data["_resultflag"] === 1) {
          localStorage.setItem("token", data["token"]);
          localStorage.setItem("user", JSON.stringify(data["user"]["data"]));
        }
      } catch (ex) {}
    });
  };

  useEffect(() => {
    // alert(localStorage.getItem("token"))
    // alert(window.location.href);

    // if (!localStorage.getItem("token")) {
    getUserInfo(true);
    // }

    // var urlParams = parseURLParams(window.location.href);

    // if (urlParams && urlParams["userid"]) {
    // var userid = urlParams && urlParams["userid"] ? urlParams["userid"][0] : "";
    // alert(urlParams && urlParams["userid"]);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/photowall" element={<PhotoWall />}></Route>
      <Route path="/thankyou" element={<ThankYou />}></Route>
      <Route path="/welcome" element={<Welcome />}></Route>
      <Route path="/wallstory" element={<WallStory />}></Route>
    </Routes>
  );
};

export default Routers;
