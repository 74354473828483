import React from "react";

const ThankYou = (props) => {
  return (
    <>
      <div className="thankyou_bg">
        <img
          src="/assets/images/chevron.svg"
          alt=""
          onClick={() => props.close()}
        />
        <img src="/assets/images/camera2.svg" className="cam_ico" alt="" />
        <h3>Thank You</h3>
        <h6>Your entry has been submitted</h6>
        <h5>Thank you for your contribution in nation-building</h5>
      </div>

      <div className="checkwall_div">
        <h5>See the Gifts of Seva from across the country</h5>
        <button onClick={() => props.pushUrl()}>
          check wall
          <img src="/assets/images/longarrow.png" alt="" />
        </button>
      </div>
    </>
  );
};

export default ThankYou;
