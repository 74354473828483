import React from "react";

function PopUp(props) {
  return (
    <div
      style={{
        width: "90%",
        zIndex: "999",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "50px",
          borderRadius: "30px",
        }}
      >
        <h4 style={{ fontSize: "15px" }}>{props.message}</h4>

        <button
          style={{
            position: "relative",
            top: "35px",
            padding: " 5px 10px",
            border: "none",
            background: "blue",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default PopUp;
