import React, { useState, useEffect, useRef } from "react";

const Loader = () => {
    return (
        <>
            <div className="loader_bg">
                <div className="loader_pos">
                    <img
                        src="/assets/images/loader.png"
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}

export default Loader;