import React from "react";
import { facebookShareLink, twitterShareLink, whatsappShareLink } from "./url";
import { parseURLParams } from "./helper";

const SharePopup = (props) => {
  const { id, created } = props;
  const share = (type) => {
    try {
      let obj = {};
      window.gtag("event", `share ${type} picture event`, obj);
    } catch (error) {}

    props.updateInfo("share", id, created);
    props.close();
  };
  var urlParams = parseURLParams(window.location.href);
  let detailurl = "";
  let detailurlFb = "https://nm-4.com/seva";
  // "https://birthdayseva.web.app/wallstory" + window.location.search;
  // let title = urlParams && urlParams["title"] ? urlParams["title"][0] : "";
  let category_hashtag =
    (props.category_hashtag && props.category_hashtag != "null"
      ? props.category_hashtag
      : "") + " ";

  let quote =
    "I have offered a special Gift of Seva for PM Modi on his birthday! You too can gift him a Seva on his special day via the NaMo App! " +
    category_hashtag +
    " #HappyBdayModiJi https://nm-4.com/seva";
  // (props.category_hashtag && props.category_hashtag != "null"
  //   ? props.category_hashtag
  //   : "") +
  // " " +
  // "#HappyBdayModiJi";

  // var facebookLink =
  //   facebookShareLink +
  //   "&link=" +
  //   encodeURIComponent(detailurlFb) +
  //   "&quote=" +
  //   encodeURIComponent(quote) +
  //   "&www.facebook.com=1";
  var facebookLink =
    facebookShareLink +
    "&href=" +
    encodeURIComponent(detailurlFb) +
    "&hashtag=" +
    encodeURIComponent(category_hashtag + "  #HappyBdayModiJi") +
    "&www.facebook.com=1";
  var twitterLink =
    twitterShareLink +
    encodeURIComponent(detailurl + " \n\n" + quote) +
    "&www.facebook.com=1";
  var whatsappLink =
    whatsappShareLink +
    encodeURIComponent(detailurl + "  " + quote) +
    "&www.facebook.com=1";
  return (
    <>
      <div className="sharepopup_bg">
        <div className="sharepopup_pos">
          <h4>
            Share
            <img
              onClick={() => props.close()}
              src="/assets/images/cross.svg"
              alt=""
            />
          </h4>
          <ul className="sharelist">
            <li
              onClick={() => {
                share("facebook");
              }}
            >
              <a href={facebookLink} target={"_blank"}>
                <img src="/assets/images/facebook.svg" alt="" />
                <h6>Facebook</h6>
              </a>
            </li>

            <li onClick={() => share("twitter")}>
              <a href={twitterLink} target={"_blank"}>
                <img src="/assets/images/twitter.svg" alt="" />
                <h6>Twitter</h6>
              </a>
            </li>

            <li onClick={() => share("whatsapp")}>
              <a href={whatsappLink} target={"_blank"}>
                <img src="/assets/images/whatsapp.svg" alt="" />
                <h6>Whatsapp</h6>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SharePopup;
