import React from "react";


const Welcome = () => {
    return (
        <>
            <div className="welocme_height">
                <div className="welcome_bg">
                    <img src="/assets/images/chevron.svg" alt="" />
                    <img src="/assets/images/camera2.svg" className="cam_ico" alt="" />
                    <h3 className="welcome_head">Welcome</h3>
                    {/* <h6>Your entry has been submitted</h6>*/}
                    <h5 className="offer">You can offer youe Gift of Seva</h5>
                    <button className="submit_ent">submit entry
                        <img src="/assets/images/longarrow.png" alt="" />
                    </button>
                </div>

                <div className="checkwall_div">
                    <h5>Or you can check the wall to see</h5>
                    <a href="#">check wall
                        <img src="/assets/images/arrowblue.svg" alt="" />
                    </a>
                </div>
            </div>

        </>
    )
};

export default Welcome;
