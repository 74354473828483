import React from "react";
import { useState, useRef, useEffect } from "react";
import { Camera } from "react-camera-pro";
import styled from "styled-components";

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;

const TakePhotoButton = styled(Button)`
  background: url("https://img.icons8.com/ios/50/000000/compact-camera.png");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 0;
    cursor: default;
    padding: 60px;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
    &:disabled {
      padding: 40px 25px;
    }
  }
`;

const ImagePreview = styled.div`
  width: 120px;
  height: 120px;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

const FullScreenImagePreview = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default function CameraComp(props) {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const camera = useRef(null);
  const [devices, setDevices] = useState([]);
  const [activeDeviceId, setActiveDeviceId] = useState(undefined);
  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == "videoinput");
      setDevices(videoDevices);
    })();
  });

  // const [openCamera, setOpenCamera] = useState(false);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [facingMode, setFacingMode] = useState(true);

  // const [image, setImage] = useState(null);
  // const camera = useRef(null);

  const videoRef = useRef(null);
  const photoRef = useRef(null);

  // const getVideo = () => {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       video: { facingMode: facingMode ? "user" : "environment" },
  //     })
  //     .then((stream) => {
  //       let video = videoRef.current;
  //       video.srcObject = stream;
  //       video.play();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const dataURLToBlob = function (dataURL) {
    var BASE64_MARKER = ";base64,";
    var parts = "";
    var contentType = "";
    var raw = "";
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(",");
      contentType = parts[0].split(":")[1];
      raw = parts[1];
      return new Blob([raw], { type: contentType });
    }
    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(":")[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  const capture = () => {
    let width = "100%";
    let height = "100%";
    let video = videoRef.current;
    let photo = photoRef.current;
    let canvas = document.getElementsByClassName("output_canvas1")[0];
    // let ctx = canvas.getContext("2d");
    // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    setHasPhoto(true);

    // let output = document.getElementsByClassName("output_canvas1")[0];
    // let link_image1 = output.toDataURL("image/png", 1);
    // let resizedImage = dataURLToBlob(link_image1);
    // console.log(resizedImage);
    // props.getImageData(resizedImage, link_image1);

    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    let link_image1 = canvas.toDataURL("image/jpeg");
    let resizedImage = dataURLToBlob(link_image1);
    props.getImageData(resizedImage, link_image1);
    console.log(resizedImage);
  };

  // useEffect(() => {
  //   if (props.camera) {
  //     getVideo();
  //   }
  // }, [props.camera]);

  // useEffect(() => {
  //   getVideo();
  // }, [facingMode]);

  useEffect(() => {
    console.log("image is ", image);
    if (image) {
      props.getImageData(image);
    }
  }, [image]);

  return (
    <>
      {/* <div className="camera">
        <>
          <video className="webcamera_pos" ref={videoRef} playsinline></video>

          <div className="cap_btn_flex">
            <button
              className="cap_btn"
              onClick={() => setFacingMode(!facingMode)}
            >
              {facingMode ? "Open Rear Camera " : "Open Front Camera"}
            </button>
            <button className="cap_btn" onClick={() => capture()}>
              Capture photo
            </button>
            <button
              className="cap_btn"
              onClick={() => {
                props.closeCamera();
                // setOpenCamera(false);
              }}
            >
              Cancel
            </button>
          </div>
        </>
        <canvas
          className="output_canvas1"
          width={376}
          height={211}
          ref={photoRef}
        ></canvas>
      </div> */}
      {/* <div className="webcamera_pos">
        <Camera
          ref={camera}
          style={{ width: "200px", position: "absolute", left: "300px" }}
        />
      </div>

      <br />
      <button
        onClick={() => {
          setImage(camera.current.takePhoto());
          console.log("hello");
          console.log(camera.current.takePhoto());
        }}
      >
        Take photo
      </button>
      <button onClick={() => console.log("hello")}>Hello</button>
      <img src={image} alt="Taken photo" /> */}
      <div className="webcamera_pos">
        <Camera
          ref={camera}
          aspectRatio="cover"
          numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
          videoSourceDeviceId={activeDeviceId}
          errorMessages={{
            noCameraAccessible:
              "You need to grant camera permissions to proceed",
            permissionDenied:
              "Permission denied. Please refresh and give camera permission.",
            switchCamera:
              "It is not possible to switch camera to different one because there is only one video device accessible.",
            canvas: "Canvas is not supported.",
          }}
        />
      </div>

      {/* <Control>
        <select
          onChange={(event) => {
            setActiveDeviceId(event.target.value);
          }}
        >
          {devices.map((d) => (
            <option key={d.deviceId} value={d.deviceId}>
              {d.label}
            </option>
          ))}
        </select> */}

      <div className="cap_btn_flex">
        {/* <ChangeFacingCameraButton
            className="cap_btn"
            disabled={numberOfCameras <= 1}
            onClick={() => {
              if (camera.current) {
                const result = camera.current.switchCamera();
                console.log(result);
              }
            }}
          /> */}
        {/* <TakePhotoButton
            className="cap_btn"
            onClick={() => {
              if (camera.current) {
                const photo = camera.current.takePhoto();
                console.log(photo);
                setImage(photo);
              }
            }}
          /> */}
        <button
          className="cap_btn"
          onClick={() => {
            setFacingMode(!facingMode);
            camera.current.switchCamera();
          }}
        >
          {facingMode ? "Open Rear Camera " : "Open Front Camera"}
        </button>
        <button
          className="cap_btn"
          onClick={() => setImage(camera.current.takePhoto())}
        >
          Take photo
        </button>
        <button
          className="cap_btn"
          onClick={() => {
            props.closeCamera();
            // setOpenCamera(false);
          }}
        >
          Cancel
        </button>
      </div>
      {/* </Control> */}
    </>
  );
}
