import React, { useState, useEffect } from "react";
import { parseURLParams } from "./helper";

const PhotoWall = (props) => {
  const [photos, setPhotos] = useState([]);
  var urlParams = parseURLParams(window.location.href);

  const deselectSelectedPhoto = (index) => {
    props.updateSelectedPhotos(index);
  };

  function blobToBase64(blob) {
    console.log("blob is ", blob);

    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  useEffect(() => {
    if (props.allPhotosSelected.length > 0) {
      setPhotos(props.allPhotosSelected);
    } else {
      props.falsePhotoSelected();
    }
  }, [props.allPhotosSelected]);
  // console.log("allphotoselected ", props.allPhotosSelected);

  console.log("photo selected ", props.cameraMode);
  return (
    <>
      <div className="photowall_section">
        <ul className="photowall_back">
          <li>
            <img
              src="/assets/images/chevron.svg"
              alt=""
              onClick={props.falsePhotoSelected}
            />
          </li>
          <li>
            {/* <h5>Gift of Seva - Photo Wall</h5> */}
            <h5>{urlParams && urlParams["title"] && urlParams["title"][0]}</h5>
          </li>
        </ul>
        <div className="multipleimage_div">
          <ul className="multiple_images">
            {photos &&
              photos.length > 0 &&
              photos.map((item, index) => {
                console.log("item", item);
                return (
                  <li key={index}>
                    <img
                      // src={blobToBase64(item)}
                      src={URL.createObjectURL(item)}
                      // className={props.cameraMode ? "img_ban" : ""}
                      alt=""
                    />
                    <img
                      onClick={() => deselectSelectedPhoto(index)}
                      src="/assets/images/crosscircle.svg"
                      className="cross_circle"
                      alt=""
                    />
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="button_bg">
          <button type="button" onClick={() => props.save(photos)}>
            Submit
            <img src="/assets/images/longarrow.png" alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default PhotoWall;
